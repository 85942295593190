import request from '@/utils/request'

// 查询学生信息列表
export function listStudent(query) {
  return request({
    url: '/demo/student/list',
    method: 'get',
    params: query
  })
}

// 查询学生信息分页
export function pageStudent(query) {
  return request({
    url: '/demo/student/page',
    method: 'get',
    params: query
  })
}

// 查询学生信息详细
export function getStudent(data) {
  return request({
    url: '/demo/student/detail',
    method: 'get',
    params: data
  })
}

// 新增学生信息
export function addStudent(data) {
  return request({
    url: '/demo/student/add',
    method: 'post',
    data: data
  })
}

// 修改学生信息
export function updateStudent(data) {
  return request({
    url: '/demo/student/edit',
    method: 'post',
    data: data
  })
}


// 删除学生信息
export function delStudent(data) {
  return request({
    url: '/demo/student/delete',
    method: 'post',
    data: data
  })
}
